export const signUpClick = (event, targetUrl) => {
  event.preventDefault()

  if (!targetUrl) {
    targetUrl = 'https://app.atlas.co/login'
  }

  var urlObj = new URL(targetUrl)
  var currentParams = new URLSearchParams(urlObj.search)

  var utm_source = sessionStorage.getItem('utm_source')
  var utm_medium = sessionStorage.getItem('utm_medium')
  var utm_campaign = sessionStorage.getItem('utm_campaign')
  var referrer = sessionStorage.getItem('referrer')
  var website_origin = sessionStorage.getItem('website_origin')
  var last_page = window.location.href

  if (utm_source) currentParams.set('utm_source', utm_source)
  if (utm_medium) currentParams.set('utm_medium', utm_medium)
  if (utm_campaign) currentParams.set('utm_campaign', utm_campaign)
  if (referrer) currentParams.set('referrer', referrer)
  if (last_page) currentParams.set('last_page', last_page)
  if (website_origin) currentParams.set('website_origin', website_origin)

  var newUrl = urlObj.origin + urlObj.pathname + '?' + currentParams.toString()

  window.location.href = newUrl
}
